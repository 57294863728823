import Vue from "vue";
import Vuex from "vuex";
import VueRouter, { RouteConfig } from "vue-router";
import Store from "@/store/index";
import { Watch } from "vue-property-decorator";

Vue.use(VueRouter);

// @ts-ignore
const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/homePage/index.vue"),
    children: [],
  },
  {
    path: "/home",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/homePage/index.vue"),
    children: [],
  },
  {
    path: "/system/admin",
    name: "system_admin",
    component: () => import(/* webpackChunkName: "about" */ "@/views/system/users/index.vue")
  },
  {
    path: "/system/menu",
    name: "menu",
    component: () => import(/* webpackChunkName: "about" */ "@/views/system/menu/index.vue")
  },
  {
    path: "/system/permission",
    name: "permission",
    component: () => import(/* webpackChunkName: "about" */ "@/views/system/permission/index.vue")
  },
  {
    path: "/system/role",
    name: "role",
    component: () => import(/* webpackChunkName: "about" */ "@/views/system/role/index.vue")
  },
  {
    path: "/system/tag",
    name: "tag",
    component: () => import(/* webpackChunkName: "about" */ "@/views/system/tag/index.vue")
  },
  {
    path: "/system/group",
    name: "group",
    component: () => import(/* webpackChunkName: "about" */ "@/views/system/group/index.vue")
  },
  {
    path: "/device",
    name: "device_management",
    component: () => import(/* webpackChunkName: "about" */ "@/views/device/index.vue")
  },

  //商铺管理
  {
    path: "/temple",
    name: "temple",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/place/temple/index.vue"),
    children: [],
  },
  {
    path: "/temple/hall",
    name: "scene",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/place/scene/index.vue"),
    children: [],
  },
  {
    path: "/user",
    name: "user_list",
    component: () => import(/* webpackChunkName: "about" */ "@/views/user/users/index.vue")
  },
  {
    path: "/place_user",
    name: "place_user_list",
    component: () => import(/* webpackChunkName: "about" */ "@/views/user/place_user/index.vue")
  },
  {
    path: "/order",
    name: "order",
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/order/orderList/index.vue"),
  },
  {
    path: "/work/otherBless",
    name: "bless",
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/work/otherBless/index.vue"),
  },
 
  {
    path: "/mine",
    name: "mine",
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/mine/index.vue"),
  },
  {
    path: "/message",
    name: "message",
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/mine/message.vue"),
  },
  {
    path: "/qrcode",
    name: "qrcode",
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/mine/qrcode.vue"),
  },
  {
    path: "/dictionaries/hall",
    name: "hall_dictionaries",
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/dictionaries/hall/index.vue"),
  },
  {
    path: "/dictionaries/subscribe",
    name: "subscribe_list",
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/dictionaries/subscribe/index.vue"),
  },
  {
    path: "/dictionaries/customer",
    name: "customer_tag_list",
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/dictionaries/customer/index.vue"),
  },
  {
    path: "/calendar",
    name: "calendar",
    component: () =>
        import(/* webpackChunkName: "about" */ "@/views/calendar/index.vue"),
  },
  {
    path: "/library",
    name: "library",
    component: () =>
        import(/* webpackChunkName: "about" */ "@/views/library/index.vue"),
  },
];

const router = new VueRouter({
  // mode:'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
